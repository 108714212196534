<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/Welcome' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>系统管理</el-breadcrumb-item>
      <el-breadcrumb-item>资源管理</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card v-if="isShowData">
      <!-- 查询 -->
      <div style="margin-bottom: 20px; display: flex; align-items: center">
        <label for=""
          >资源名称：
          <el-input
            style="width: 150px"
            size="small"
            placeholder="请输入资源名称"
            v-model="query.name"
            clearable
        /></label>
          <label for=""
          >访问路径：
              <el-input
                      style="width: 150px"
                      size="small"
                      placeholder="请输入访问路径"
                      v-model="query.path"
                      clearable
              /></label>
        <label style="margin: 0 10px" for=""
          >资源类型：
          <el-select
            v-model="query.type"
            clearable
            size="small"
            placeholder="请选择资源类型"
          >
            <el-option label="菜单" :value="0"></el-option>
            <el-option label="接口" :value="1"></el-option>
          </el-select>
        </label>
        <label for=""
          >创建时间：<el-date-picker
            style="width: 185px"
            v-model="createTimeBegin"
            type="datetime"
            value-format="timestamp"
            size="small"
            placeholder="选择日期时间"
          >
          </el-date-picker>
          至
          <el-date-picker
            style="width: 185px"
            v-model="createTimeEnd"
            type="datetime"
            value-format="timestamp"
            size="small"
            default-time="23:59:59"
            placeholder="选择日期时间"
            @change="inTimeEndchange"
          >
          </el-date-picker
        ></label>
        <el-button
          style="margin-left: 10px"
          type="primary"
          size="small"
          @click="querySysResourcecx"
          >查询</el-button
        >
        <el-button size="small" @click="querySysResourcecz">重置</el-button>
      </div>
      <!-- 点击添加显示对话框 -->
      <div class="topbtn">
        <div>
          <el-button
            type="primary"
            size="small"
            icon="el-icon-plus"
            @click="isShowAdd = true"
            >新增</el-button
          >
          <!-- <el-button type="danger" size="small" icon="el-icon-delete" @click="batchdel">批量删除</el-button> -->
        </div>
        <div>
          <div style="fontsize: 13px">
            <span style="color: red">当前资源路径：</span>
            <span @click="pathClick" ref="paths">资源管理</span>
            <span v-if="isShowchild"> - 子节点</span>
          </div>
        </div>
      </div>
      <hr />
      <!-- 表格 -->
      <transition appear tag="div">
        <el-table
          v-loading="loading"
          ref="multipleTable"
          :data="tableData"
          stripe
          @selection-change="handleSelectionChange"
          tooltip-effect="dark"
          style="width: 100%"
        >
          <!-- <el-table-column header-align="left" align="left" type="selection" width="55"></el-table-column> -->
          <el-table-column
            header-align="left"
            align="left"
            prop="id"
            label="编号"
            width="60"
          ></el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="name"
            label="资源名称"
          ></el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="type"
            label="资源类型"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.type === 0">菜单</span>
              <span v-if="scope.row.type === 1">接口</span>
            </template>
          </el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="parentId"
            label="父节点ID"
          ></el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="path"
            label="访问路径"
          ></el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="createTime"
            label="创建时间"
            sortable
            width="200"
          >
            <template slot-scope="scope">
              <span>{{ conversionTime(scope.row.createTime) }}</span>
            </template>
          </el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            label="操作"
            width="200"
            fixed="right"
          >
            <template slot-scope="scope">
              <el-button
                type="text"
                @click="childrenDialog(scope)"
                size="mini"
                icon="el-icon-share"
                v-if="isSHowChildren"
                >子节点</el-button
              >
              <el-button
                type="text"
                @click="isShoweditDialog(scope)"
                size="mini"
                icon="el-icon-edit"
                >编辑</el-button
              >
              <el-button
                type="text"
                @click="delClick(scope.row.id)"
                size="mini"
                icon="el-icon-delete"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </transition>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="query.current"
        :page-sizes="[10, 50, 100]"
        :page-size="query.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
    <!-- 添加 -->
    <el-dialog
      title="添加资源"
      :visible.sync="isShowAdd"
      @close="addClose"
      width="35%"
      v-el-drag-dialog
    >
      <el-form
        :model="addForm"
        :rules="formRules"
        ref="addRef"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="资源名称" prop="name">
          <el-input
            v-model="addForm.name"
            placeholder="请输入资源名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="排序" prop="sort">
          <el-input
            v-model="addForm.sort"
            placeholder="请输入排序位置"
          ></el-input>
        </el-form-item>
        <el-form-item label="选择父节点" prop="parentId">
          <el-tree
            :data="treeData"
            :props="defaultProps"
            :expand-on-click-node="false"
            :filter-node-method="filterNode"
            ref="tree"
            node-key="id"
            highlight-current
            @node-click="handleNodeClick"
          >
          </el-tree>
        </el-form-item>
        <el-form-item label="资源类型" prop="type">
          <el-select
            v-model="addForm.type"
            size="small"
            placeholder="请选择资源类型"
          >
            <el-option label="菜单" :value="0"></el-option>
            <el-option label="接口" :value="1"></el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="菜单图标"  prop="icon">
              <el-popover
                placement="bottom-start"
                width="460"
                trigger="click"
                @show="$refs['iconSelect'].reset()"
              >
                <IconSelect ref="iconSelect" @selected="selected" />
                <el-input slot="reference" v-model="addForm.icon" placeholder="点击选择图标" readonly>
                  <i
                    v-if="addForm.icon"
                    slot="prefix"
                    :icon-class="addForm.icon"
                    class="el-input__icon"
                    style="height: 32px;width: 16px;"
                  />
                  <i v-else slot="prefix" class="el-icon-search el-input__icon" />
                </el-input>
              </el-popover>
            </el-form-item> -->
        <el-form-item label="资源路径" prop="path">
          <el-input
            v-model="addForm.path"
            placeholder="请输入资源路径"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="isShowAdd = false">取 消</el-button>
        <el-button type="primary" @click="addClick">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 编辑 -->
    <el-dialog
      title="编辑资源"
      :visible.sync="isShowEdit"
      width="35%"
      v-el-drag-dialog
    >
      <el-form
        :model="editForm"
        :rules="formRules"
        ref="editRef"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="资源名称" prop="name">
          <el-input
            v-model="editForm.name"
            placeholder="请输入资源名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="排序" prop="sort">
          <el-input
            v-model="editForm.sort"
            placeholder="请输入排序位置"
          ></el-input>
        </el-form-item>
        <el-form-item label="选择父节点" prop="parentId">
          <el-tree
            :data="treeData"
            :props="defaultProps"
            :expand-on-click-node="false"
            :filter-node-method="filterNode"
            ref="tree"
            :default-expanded-keys="[editForm.parentId ? editForm.parentId : 1]"
            node-key="id"
            highlight-current
            @node-click="handleNodeClick"
          >
          </el-tree>
        </el-form-item>
        <el-form-item label="资源类型" prop="type">
          <el-select
            v-model="editForm.type"
            size="small"
            placeholder="请选择资源类型"
          >
            <el-option label="菜单" :value="0"></el-option>
            <el-option label="接口" :value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="资源路径" prop="path">
          <el-input
            v-model="editForm.path"
            placeholder="请输入资源路径"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="isShowEdit = false">取 消</el-button>
        <el-button type="primary" @click="editClick">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import IconSelect from '@/components/icon'
import {
  querySysResource,
  findResourceRecursion,
  addSysResource,
  delSysResource,
  querySysResourceid,
} from '@/api/system.js'
export default {
  components: {
    IconSelect,
  },
  data() {
    return {
      loading: false,
      isShowData: false,
      isShowAdd: false,
      isShowEdit: false,
      isSHowChildren: true,
      isShowchild: false,
      tableData: [],
      createTimeBegin: undefined, // 创建时间起始
      createTimeEnd: undefined, // 创建时间截至
      query: {
        // 查询条件
        current: 1,
        size: 10,
        path: undefined, // 访问路径
        name: undefined, // 资源名称
        type: undefined, // 资源类型0-菜单 1-接口
        createTimeBegin: undefined, // 创建时间起始
        createTimeEnd: undefined, // 创建时间截至
      },
      total: undefined,
      formRules: {
        // 添加表单的验证规则
        name: [
          { required: true, message: '请输入资源名称', trigger: 'blur' },
          {
            min: 1,
            max: 99,
            message: '长度在 1 到 99 个字符',
            trigger: 'blur',
          },
        ],
        type: [
          { required: true, message: '请选择资源类型', trigger: 'change' },
        ],
      },
      addForm: {
        // 添加
        name: undefined,
        type: undefined,
        parentId: undefined,
        path: undefined,
        sort: undefined,
      },
      editForm: {
        // 添加
        id: undefined,
        name: undefined,
        type: undefined,
        parentId: undefined,
        path: undefined,
        sort: undefined,
      },
      treeData: undefined,
      i: 0,
      defaultProps: {
        children: 'sunList',
        label: 'name',
      },
      batchid: '', // 保存批量选中的id
    }
  },
  created() {
    this.findResourceRecursion()
  },
  methods: {
    // 选择图标
    selected(name) {
      this.form.icon = name
    },
    querySysResource() {
      // 获取资源管理列表
      this.query.createTimeBegin = this.createTimeBegin
      this.query.createTimeEnd = this.createTimeEnd
      if (this.query.createTimeBegin && !this.query.createTimeEnd) {
        return this.$message.warning('请输入截止时间')
      } else if (!this.query.createTimeBegin && this.query.createTimeEnd) {
        return this.$message.warning('请输入开始时间')
      }
      if (this.query.createTimeBegin || this.query.createTimeEnd) {
        this.query.createTimeBegin = (
          this.query.createTimeBegin / 1000
        ).toFixed(0)
        this.query.createTimeEnd = (this.query.createTimeEnd / 1000).toFixed(0)
      }
      querySysResource(this.query).then((res) => {
        if (res.data.code === '200') {
          this.total = res.data.data.total
          this.tableData = res.data.data.list
          if (this.tableData) {
            this.isShowData = true
            this.loading = false
          }
        }
      })
    },
    querySysResourcecx() {
      // 查询
      this.query.current = 1
      this.querySysResource()
    },
    querySysResourcecz() {
      this.query.current = 1
      this.query.size = 10
      this.query.name = undefined
      this.query.path = undefined
      this.query.type = undefined
      this.createTimeBegin = undefined
      this.createTimeEnd = undefined
      this.querySysResource()
    },
    findResourceRecursion() {
      // 查询所有的权限列表
      findResourceRecursion().then((res) => {
        if (res.data.code === '200') {
          this.treeData = res.data.data
          this.querySysResource()
        }
      })
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`)
      this.loading = true
      this.query.size = val
      this.querySysResource()
    },
    handleCurrentChange(val) {
      this.loading = true
      console.log(`当前页: ${val}`)
      this.query.current = val
      this.querySysResource()
    },
    filterNode(value, data) {
      // 筛选节点
      if (!value) return true
      return data.label.indexOf(value) !== -1
    },
    handleNodeClick(data) {
      // 节点单击事件
      this.addForm.parentId = data.id
      this.editForm.parentId = data.id
    },
    addClose() {
      // 监听添加用户对话框的关闭事件 清空input数据
      this.$refs.addRef.resetFields()
    },
    addClick() {
      // 添加
      this.$refs.addRef.validate((valid) => {
        if (!valid) return
        addSysResource(this.addForm).then((res) => {
          if (res.data.code === '200') {
            this.isShowAdd = false
            this.$message.success('添加成功')
            this.querySysResource()
            this.findResourceRecursion()
            this.$refs.tree.setCurrentKey()
          }
        })
      })
    },
    isShoweditDialog(scope) {
      // 编辑显示弹框
      var value = JSON.parse(JSON.stringify(scope.row))
      this.editForm = value
      this.$nextTick(() => {
        this.$refs.tree.setCurrentKey(this.editForm.parentId)
      })
      this.isShowEdit = true
    },
    editClick() {
      // 编辑
      addSysResource(this.editForm).then((res) => {
        if (res.data.code === '200') {
          this.isShowEdit = false
          this.$message.success('编辑成功')
          window.location.reload()
        }
      })
    },
    delClick(id) {
      // 删除
      this.$confirm('此操作将永久删除该文件, 是否继续?', '删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          delSysResource(id).then((res) => {
            if (res.data.code === '200') {
              this.$message.success('删除成功')
              window.location.reload()
            }
          })
        })
        .catch(() => {
          this.$message.info('已取消删除')
        })
    },
    childrenDialog(scope) {
      // 子节点
      this.query.parentId = scope.row.parentId
      this.querySysResource()
      this.isSHowChildren = false
      this.isShowchild = true
      console.log(this.$refs.paths.innerHTML)
      this.$refs.paths.style.color = '#409EFF'
      this.$refs.paths.style.fontWeight = '600'
      this.$refs.paths.style.cursor = 'pointer'
    },
    pathClick() {
      if (this.isShowchild) {
        window.location.reload()
      }
    },
    handleSelectionChange(val) {
      // 点击table选中
      this.batchid = ''
      val.forEach((item) => {
        this.batchid += item.id + '_'
      })
      this.batchid = this.batchid.slice(0, this.batchid.length - 1)
    },
    batchdel() {
      // 批量删除
      this.$confirm('此操作将永久删除该文件, 是否继续?', '删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          delSysResource(this.batchid).then((res) => {
            if (res.data.code === '200') {
              this.$message.success('删除成功')
              window.location.reload()
            }
          })
        })
        .catch(() => {
          this.$message.info('已取消删除')
        })
    },
    // 结束时间选择
    inTimeEndchange() {
      if (this.createTimeBegin === null || this.createTimeBegin === undefined) {
        this.createTimeEnd = null
        return this.$message.warning('请选择开始时间')
      } else if (this.createTimeEnd < this.createTimeBegin) {
        this.createTimeEnd = this.createTimeBegin + 3600 * 1000 * 24 * 1
        return this.$message.warning('不可选择小于开始时间的日期')
      }
    },
  },
}
</script>
<style lang="scss" scoped>
::v-deep
  .el-tree--highlight-current
  .el-tree-node.is-current
  > .el-tree-node__content {
  color: #409eff;
}
.topbtn {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
