/*
 * @Author: sun
 * @Date: 2022-03-15 10:50:55
 * @LastEditTime: 2022-10-19 08:47:46
 * @FilePath: \cloud-platform\src\network\request.js
 * @Description: 
 */
import axios from 'axios'
import router from '../router'
import VueCookies from 'vue-cookies'
import { Notification, MessageBox, Message } from 'element-ui'

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'

export function request(config, res){
	const instance = axios.create({
    baseURL: process.env.VUE_APP_BASE_API,
		timeout: 30000
  })
  // 首先对拦截器的请求进行设置，并在方法中return config，此处为固定格式
  instance.interceptors.request.use(config => {
    // 表示在配置中的设置头消息的字段Authorization为从本地获取的token值
    config.headers.Authorization = VueCookies.get('token')
    config.headers.app_name = 'cloud-platform'
    return config
  }, error => {
    console.log('err' + error)
    let { message } = error;
    if (message == "Network Error") {
      message = "后端接口连接异常";
    }
    else if (message.includes("timeout")) {
      message = "系统接口请求超时";
    }
    else if (message.includes("Request failed with status code")) {
      message = "系统接口" + message.substr(message.length - 3) + "异常";
    }
    Message({
      message: message,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  })
  // 响应拦截器
  instance.interceptors.response.use(res => {
    const code = res.data.code
    if(code === '1001') {
      Message({
        message: '登录状态已过期',
        type: 'error'
      })
      VueCookies.remove('token')
      setTimeout(function () {
        router.replace({
          path: '/Login'
        })
      }, 1500)
      return
    } else if (code === '500') {
      Message({
        message: res.data.msg,
        type: 'error'
      })
    } else if (code !== '200') {
      Notification.error({
        title: res.data.msg
      })
    }
    return res
  }, error => {
    console.log('err' + error)
    let { message } = error;
    if (message == "Network Error") {
      message = "后端接口连接异常";
    }
    else if (message.includes("timeout")) {
      message = "系统接口请求超时";
    }
    else if (message.includes("Request failed with status code")) {
      message = "系统接口" + message.substr(message.length - 3) + "异常";
    }
    Message({
      message: message,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  })
	return instance(config, res)
}
