import { request } from '../network/request.js';

export function querySysRole(query) {
  // 角色列表
  return request({
    url: '/parking/saas/sysRole',
    method: 'get',
    params: query,
  });
}

export function addSysRole(data) {
  // 保存角色
  return request({
    url: '/parking/saas/sysRole',
    method: 'post',
    data: data,
  });
}

export function delSysRole(id) {
  // 删除角色
  return request({
    url: '/parking/saas/sysRole/' + id,
    method: 'delete',
  });
}

export function findResourceRecursion() {
  // 递归查询资源列表
  return request({
    url: '/parking/saas/sysResource/findResourceRecursion',
    method: 'get',
  });
}

export function sysRoleId(id) {
  // 根据id查询角色权限
  return request({
    url: '/parking/saas/sysRole/' + id,
    method: 'get',
  });
}

export function addSaveRoleResource(data) {
  // 保存角色资源
  return request({
    url: '/parking/saas/sysRole/saveRoleResource',
    method: 'post',
    data: data,
  });
}

export function querySysResource(query) {
  // 查询系统资源列表
  return request({
    url: '/parking/saas/sysResource',
    method: 'get',
    params: query,
  });
}

export function addSysResource(data) {
  // 保存系统资源
  return request({
    url: '/parking/saas/sysResource',
    method: 'post',
    data: data,
  });
}

export function delSysResource(id) {
  // 删除系统资源
  return request({
    url: '/parking/saas/sysResource/' + id,
    method: 'delete',
  });
}

export function findSysAdminPage(data) {
  // 系统用户列表查询
  return request({
    url: '/parking/saas/pkInfo/findSysAdminPage',
    method: 'post',
    data: data,
  });
}

export function addSysAdmin(data) {
  // 保存系统用户
  return request({
    url: '/parking/saas/sysAdmin',
    method: 'post',
    data: data,
  });
}

export function getfindAllRoles() {
  // 获取所有系统角色
  return request({
    url: '/parking/saas/sysRole/findAllRoles',
    method: 'get',
  });
}

export function delSysAdmin(id) {
  // 删除系统用户
  return request({
    url: '/parking/saas/sysAdmin/' + id,
    method: 'delete',
  });
}

export function editSysAdmin(id) {
  // 根据id查询系统用户
  return request({
    url: '/parking/saas/sysAdmin/' + id,
    method: 'get',
  });
}

export function resetPassword(data) {
  // 重置密码
  return request({
    url: '/parking/saas/sysAdmin/resetPassword',
    method: 'post',
    data: data,
  });
}
